import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
require('@/assets/scss/style.scss')
import store from './store'
import gv from './mixins/globalValiables';
import api from './mixins/api';

createApp(App)
  .use(Antd)
  .mixin(gv)
  .use(store)
  .mixin(api)
  .mount('#app')