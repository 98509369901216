<template>
    <a-alert
        v-if="alert"
        v-bind:type="alertType"
        v-bind:message="alertMessage"
        v-bind:description="alertDescription"
        show-icon
    ></a-alert>
</template>
<script>
import { defineComponent } from 'vue';
import useWeb3 from '../composables/useWeb3'
export default defineComponent({
    setup() {
        const {alert, alertType, alertMessage, alertDescription} = useWeb3()

        return {
            alert,
            alertType,
            alertMessage,
            alertDescription
        };
    },
});
</script>
