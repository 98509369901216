<template>
    <a-form
        :model="formState"
        name="contact"
        autocomplete="off"
        class="form-contact"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
    >
        <a-form-item
            label="Name"
            name="name"
            has-feedback
            :rules="[
                {
                    required: true,
                    message: '氏名を入力は必須です。',
                },
            ]"
        >
            <a-input v-model:value="formState.name" placeholder="氏名を入力してくだい。" />
        </a-form-item>
        <a-form-item
            label="Campany"
            name="campany"
            has-feedback
            :rules="[]"
        >
            <a-input v-model:value="formState.campany" placeholder="会社名を入力してください" />
        </a-form-item>
        <a-form-item
            label="Mail"
            name="mail"
            has-feedback
            :rules="[
                {
                    required: true,
                    message: 'メールアドレスの入力は必須です。',
                },
            ]"
        >
            <a-input type="email" v-model:value="formState.mail" placeholder="メールアドレスを入力してください" />
        </a-form-item>
        <a-form-item
            label="Tel."
            name="tel"
            has-feedback
            :rules="[]"
        >
            <a-input v-model:value="formState.tel" placeholder="電話番号を入力してください" />
        </a-form-item>
        <a-form-item
            label="Inquiry"
            name="inquiry"
            has-feedback
            :rules="[]"
        >
            <a-textarea v-model:value="formState.inquiry" placeholder="お問い合わせ内容"></a-textarea>
        </a-form-item>
        <a-alert
            v-if="alert.show"
            v-bind:type="alert.type"
            v-bind:message="alert.message"
            v-bind:description="alert.description"
            show-icon
        ></a-alert>
        <div class="btn-wrap">
            <a-button class="btn btn-primary" html-type="submit">送信する</a-button>
        </div>
    </a-form>
</template>
<script>
import api from '@/mixins/api';
import { defineComponent, reactive } from 'vue';
export default defineComponent({
    setup() {
        // フォームの値
        const formState = reactive({
            name: "",
            campany: "",
            mail: "",
            tel: "",
            inquiry: ""
        })
        let alert = reactive({
            show: false,
            type: false,
            message: false,
        });

        // フォームが送信されたが、バリデーションに失敗した時の処理
        const onFinishFailed = async () => {
            alert.show = true
            alert.type = 'error'
            alert.message = '入力内容を確認してください。'
        }

        // フォームが送信された時の処理
        const onFinish = async (values) => {
            console.log(values);
            await api.methods.postContact(values);
            formState.name = '';
            formState.campany = '';
            formState.mail = '';
            formState.tel = '';
            formState.inquiry = '';
            alert.show = true
            alert.type = 'success'
            alert.message = 'お問い合わせありがとうございます。担当者より折り返しご連絡いたします。'
        }

        return {
            formState,
            onFinish,
            onFinishFailed,
            alert
        }
    }
});
</script>
