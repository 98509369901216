export const networkConfigs = {
    "0x1": {
        chainId: 1,
        chainName: "ETH",
        currencySymbol: "ETH",
        blockExplorerUrl: "https://etherscan.io/",
        wrapped: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    },
    "0x3": {
        chainId: 3,
        chainName: "Ropsten",
        currencySymbol: "ROP",
        blockExplorerUrl: "https://ropsten.etherscan.io/",
    },
    "0x4": {
        chainId: 4,
        chainName: "Rinkeby",
        currencySymbol: "RIN",
        blockExplorerUrl: "https://rinkeby.etherscan.io/",
    },
    "0x5": {
        chainId: 5,
        chainName: "Goerli",
        currencySymbol: "KOV",
        blockExplorerUrl: "https://goerli.etherscan.io/",
    },
    "0x2a": {
        chainId: 42,
        chainName: "Kovan",
        currencySymbol: "KOV",
        blockExplorerUrl: "https://kovan.etherscan.io/",
    },
    "0x539": {
        chainName: "Local",
        currencyName: "ETH",
        currencySymbol: "ETH",
        rpcUrl: "http://127.0.0.1:7545",
    },
    "0x89": {
        chainId: 137,
        chainName: "Polygon",
        currencyName: "MATIC",
        currencySymbol: "MATIC",
        rpcUrl: "https://rpc-mainnet.maticvigil.com/",
        blockExplorerUrl: "https://explorer-mainnet.maticvigil.com/",
    },
    "0x13881": {
        chainId: 80001,
        chainName: "Mumbai",
        currencyName: "MATIC",
        currencySymbol: "MATIC",
        rpcUrl: "https://rpc-mumbai.matic.today/",
        blockExplorerUrl: "https://mumbai.polygonscan.com/",
    },
};

export const getNativeByChain = (chain) => networkConfigs[chain]?.chainName || "NATIVE";

export const getChainById = (chain) => networkConfigs[chain]?.chainId || null;

export const getExplorer = (chain) => chain ? networkConfigs[chain]?.blockExplorerUrl : null;

export const getWrappedNative = (chain) =>
    networkConfigs[chain]?.wrapped || null;