<template>
    <a-spin v-if="inProgress">
        <a-alert
            v-bind:message="inProgressMessage"
            v-bind:description="inProgressDescription"
        ></a-alert>
    </a-spin>
</template>
<script>
import { defineComponent } from 'vue';
import useWeb3 from '../composables/useWeb3'
export default defineComponent({
    setup() {
        const {inProgress, inProgressMessage, inProgressDescription} = useWeb3()
        return {
            inProgress,
            inProgressMessage,
            inProgressDescription
        };
    },

});
</script>
